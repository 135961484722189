/**
 * @generated SignedSource<<f88313a8d916461a9631ece5768d56b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendErrorReportInput = {
  clientMutationId?: string | null;
  errorReportInfo: ToolsSendErrorReportInput;
};
export type ToolsSendErrorReportInput = {
  body: string;
  subject: string;
};
export type ToolsSendErrorReportMutation$variables = {
  input: SendErrorReportInput;
};
export type ToolsSendErrorReportMutation$data = {
  readonly sendErrorReport: {
    readonly errors: ReadonlyArray<{
      readonly messages: ReadonlyArray<string>;
      readonly path: string;
    }>;
  };
};
export type ToolsSendErrorReportMutation = {
  response: ToolsSendErrorReportMutation$data;
  variables: ToolsSendErrorReportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messages",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToolsSendErrorReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendErrorReportPayload",
        "kind": "LinkedField",
        "name": "sendErrorReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToolsSendErrorReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendErrorReportPayload",
        "kind": "LinkedField",
        "name": "sendErrorReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5170829ed286148497fbd45bd9167f6c",
    "id": null,
    "metadata": {},
    "name": "ToolsSendErrorReportMutation",
    "operationKind": "mutation",
    "text": "mutation ToolsSendErrorReportMutation(\n  $input: SendErrorReportInput!\n) {\n  sendErrorReport(input: $input) {\n    errors {\n      path\n      messages\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33e37d5a069f6fce32b83f381cc2bba7";

export default node;
